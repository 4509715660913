<template>
    <div>
          <el-dialog :title="config.title" :visible.sync="dialogModel" :closeOnClickModal="false">
            <el-form class="elForm">
                <el-form-item 
                v-for="(v,i) in formItemList"
                :key="i"
                :label="`${v.label}：`"
                :label-width="config.formLabelWidth">
                    <img class="must" src="../assets/images/must.png"/>
                    <!-- <p class="tip">请输入{{v.label}}</p> -->
                    <el-input 
                        v-if="v.type=='input'"
                        max='100'
                        v-model="v.value" 
                        clearable 
                        :placeholder="`请输入${v.label}`"
                    ></el-input>
                    <!-- 数字框 -->
                    <el-input 
                    v-if="v.type=='number'"
                    v-model="v.value" 
                    type="number" 
                    min="1" 
                    max="10000" 
                    clearable
                    style="width:34.5%"
                    :placeholder="`请输入${v.label}`"> 
                    </el-input>
                    <!-- 单选按钮 -->
                    <el-radio-group 
                    v-if="v.type=='radio'"
                    v-model="v.value">
                        <el-radio 
                        v-for="(item,k) in v.arr"
                        :key="k"
                        :label="item.value">{{item.name}}</el-radio>
                    </el-radio-group>
                    <!-- 区省市 -->
                    <el-cascader
                        v-if="v.type=='cascader'"
                        clearable
                        :options="options"
                        :props='props'
                        v-model="v.value"
                        :placeholder="`请选择${v.label}`">
                    </el-cascader>
                    <!-- 文本域 -->
                    <el-input 
                    v-if="v.type=='textarea'"
                    type="textarea" 
                    v-model="v.value" 
                    :rows="v.row" 
                    :placeholder="`请输入${v.label}`"></el-input>
                    
                    <!-- 下拉框 -->
                    <el-select 
                     v-if="v.type=='select'"
                     v-model="v.value" 
                     :placeholder="`请选择${v.label}`">
                        <el-option
                        v-for="(item,k) in v.arr"
                        :key="k"
                        :label="v.selectlabel?item[v.selectlabel]:item"
                        :value="v.selectValue?item[v.selectValue]:item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogModel = false" size="small">取 消</el-button>
                <el-button type="primary" @click="sure" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { provinceAndCityData } from 'element-china-area-data'
export default {
    props:{
        config:{
            type:Object,
            default:()=>{return {}}
        },
        formItemList:{
            type:Array,
            default:()=>{return []}
        }
    },
    data () {
        return {
            dialogModel:false,
            props:{
                value:"label"
            },
            options: provinceAndCityData, 
        }
    },
    methods:{
        sure(){
            let {formItemList}=this;
            let tip;
            for(let e of formItemList){
                if(e.require){
                    if(!e.value||e.value.length==0){
                        tip=e.label
                        this.$message({
                            type:"warning",
                            message:`请输入${tip}`
                        })
                        return false
                    }
                }
            }
            this.$emit('getFormItemList',this.formItemList)
        },
        show(){
            this.dialogModel=!this.dialogModel
        }
    },
}
</script>
<style lang="scss" scoped>
.elForm{
    .el-form-item{
        position: relative;
        .must{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            left: -100px;
        }
        .tip{
            font-size: 12px;
            color: red;
            position: absolute;
            top: 30px;
            left: 6px;
            height: 20px;
            margin: 0px;
        }
    }
}
 .el-form-item__content{
    position: absolute;
}
</style>