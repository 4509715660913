<template>
    <div class="review">
        <el-page-header @back="goBack" content="企业审核" >
        </el-page-header>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
            type="index"
            label="#序号"
            width="60">
            </el-table-column>
            <el-table-column 
            label="状态"
            width="100">
                <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.companyNeedMap?scope.row.companyNeedMap.isPass==2?'success':
                    scope.row.companyNeedMap.isPass==3?'danger':'info'
                    :''"
                    disable-transitions>
                    {{scope.row.companyNeedMap?scope.row.companyNeedMap.isPass==2?'已通过':
                    scope.row.companyNeedMap.isPass==3?'未通过':'未审核'
                    :''}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column
            prop="companyName"
             :show-overflow-tooltip="true"
            label="企业名称">
            </el-table-column>
            <el-table-column
            prop="companyTradeName"
             :show-overflow-tooltip="true"
            label="行业类别">
            </el-table-column>
            <el-table-column
            prop="companyScale"
             :show-overflow-tooltip="true"
            label="公司规模">
            </el-table-column>
             <el-table-column
             :show-overflow-tooltip="true"
            label="所在区域">
                <template slot-scope="scope">
                   {{scope.row.province}}/{{scope.row.city}}
                </template>
            </el-table-column>
            <el-table-column
             :show-overflow-tooltip="true"
            label="岗位需求数量">
                <template slot-scope="scope">
                   {{scope.row.jobList?scope.row.jobList.length:0}}
                </template>
            </el-table-column>
                
            <el-table-column
             :show-overflow-tooltip="true"
            label="招聘人数">
                <template slot-scope="scope">
                   {{scope.row.companyNeedMap?scope.row.companyNeedMap.personNum:0}}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="mini"
                    type="primary"
                    @click="lookCompany(scope.row,scope.row.id)"
                    >企业详情</el-button>               
                </template>
            </el-table-column>
            <el-table-column label="审核">
                <template  slot-scope="scope">
                    <el-button
                    v-if="scope.row.companyNeedMap?scope.row.companyNeedMap.isPass==1:''"
                    size="mini"
                    type="danger" 
                    @click="review(scope.row.id)"
                   >审核</el-button>
                   <el-tag
                   v-else
                    :type="scope.row.companyNeedMap?scope.row.companyNeedMap.isPass==2?'success':
                    scope.row.companyNeedMap.isPass==3?'danger':'info'
                    :''"
                    disable-transitions>
                    {{scope.row.companyNeedMap?scope.row.companyNeedMap.isPass==2?'已通过':
                    scope.row.companyNeedMap.isPass==3?'未通过':'未审核'
                    :''}}
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>
         <paging v-show="tableData.length>0" :totalpage="totalpage" @getInitList="getInitList" ref="pages" v-on:handle="handle"></paging>
        
        <commonDialog 
        :config='config' 
        :formItemList='formItemList' 
        ref="dialog"
        @getFormItemList='getFormItemList'
        ></commonDialog>
    </div>
</template>
<script>
import commonDialog from '../../components/commonDialog'
export default {
     components:{
       commonDialog 
    },
    data () {
        return {
            config:{
                title:'审核',
                formLabelWidth:'100px',
            },
            formItemList:[
                {type:'radio',label:"是否通过",value:'2',key:'isPass',require:true,
                arr:[{value:"2",name:"审核通过"},{value:'3',name:"审核不通过"}]},
            ],
            formLabelWidth:'100px',
            tableData:[],
            totalpage:1,
            doubleId:null,
            comId:null,
            current:0,
         
        }
    },
    methods: {
        getFormItemList(arr){
            let obj={}
            arr.forEach(e=>{
                obj[e.key]=e.value
            })
            let userId=sessionStorage.getItem('id')
            const {isPass}=obj
            let url=`/tea/dc/exa?dcId=${this.doubleId}&companyId=${this.comId}&isPass=${isPass}&userId=${userId}`
            this.$htp.get_(url,true).then(()=>{
                 this.$refs.dialog.show()
                 this.getInitList(this.current)  
            }) 
        },
        getInitList(current=0){
            let url=`/tea/dc/apply/list?dcId=${this.doubleId}&page=${current}`
            this.$htp.get_(url).then((res)=>{
                this.tableData=res.data.rows        
                this.totalpage=res.data.total               
            }) 
        },
        handle(val){
            this.current=val  
        }, 
        //查看公司详情
        lookCompany(row){
            this.$router.push({name:'companyDetail',params:{row,id:this.doubleId}})
        },
        review(id){
            this.comId=id
            this.$refs.dialog.show()          
        },
        goBack(){
            this.$router.push({path:'/companyReview'})
        }
    },
    mounted(){
        this.doubleId=this.$route.query.id
        this.getInitList(0)
    }
}
</script>
<style lang="scss" scoped>
.el-table{
    margin-top: 20px;
}
.el-page-header{
    margin-top: 10px;
}
</style>
